<header>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
        <div class="logo_block"><a routerLink="/" class="logo_a"><img src="{{globals.cndURL}}assets/images/logo-email.png" alt=""
              class="img-fluid newimg" /></a>
              <span>
                <!-- Self Assessment Tool -->
                 <span class="qa_testing_block" *ngIf="globals.TestMode">Beta Version</span>
              </span>
            </div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 textcenter_mobile">
      
        <nav class="navbar navbar-expand-lg navbar-light top_navigation_block" *ngIf="!(globals.authData!=undefined)">
          <ul class="navbar-nav">
            <li><a href="https://www.amsn.org/about-amsn">About IFMA</a></li>
            <li><a href="https://my.amsn.org/eweb/">Store</a></li>
            <li><a href="https://www.amsn.org/about-amsn/contact-us">Contact us</a></li>
            <li><a href="https://community.amsn.org/home">Community Hub</a></li>
            <li><a href="https://www.amsn.org/about-amsn/newsroom">Newsroom</a></li>
            <li><a href="https://www.amsn.org/business-solutions">Business Solutions</a></li>
            <li class="login_li"><a routerLink="/login">Login/Create Account</a></li>
          </ul>
        </nav>
        <div class="user_dropdown without_org_block" *ngIf="globals.authData!=undefined">
         
                    
          <div class="header_dropdown dropdown">
            <button class="dropdown-toggle user_button" id="profiledb" (click)="dropdown_open()">
              <i class="fa fa-user icon_user"></i>
              <span class="name"
                [ngClass]="{'name_padding':!(globals.institute_details && globals.institute_details.role_name!='')}"><strong class="pe-none">{{globals.authData.first_name}}
                {{globals.authData.last_name}} </strong>
                <span class="id_box mb-0 ms-2">{{globals.authData.amsn_user_id}}</span>
                <i *ngIf="globals.institute_details" class="role_margin"><b>Current Role:</b>{{globals.institute_details.role_name}}</i>
              </span>
            </button>
           
          </div>
          
          <div class="logout_switch_org float-end only_login">
            <button kendoButton (click)="logout()" class="logout_btn">
              <i class="fa fa-sign-out"></i>Logout
            </button>
           </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="nagivation_block" *ngIf="!(globals.authData!=undefined)">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="dropdown"><a href="https://www.amsn.org/membership">Membership</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/membership/join-or-renew-your-membership">Join
                        or Renew Your Membership</a></li>
                    <li><a href="https://community.amsn.org/home">Community Hub</a></li>
                    <li><a
                        href="https://www.amsn.org/about-amsn/leadership-and-governance/committees-volunteer-units">Committees
                        & Volunteer Units</a>
                      <ul class="submenu dropdown-menu">
                        <li><a
                            href="https://www.amsn.org/about-amsn/leadership-and-governance/committees-volunteer-units/volunteer-opportunities">Volunteer
                            Opportunities</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.amsn.org/membership/chapters">Chapters</a></li>
                    <li><a href="https://www.amsn.org/membership/share-your-member-news">Share Your
                        Member News</a>
                      <ul class="submenu dropdown-menu">
                        <li><a href="https://www.amsn.org/membership/share-your-member-news/your-own-words">In
                            Your Own Words</a></li>
                        <li><a href="https://www.amsn.org/membership/share-your-member-news/members-move">Members
                            on The Move</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/certification">Certification</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/certification/why-earn-certification">Why Earn
                        Certification?</a></li>
                    <li><a href="https://www.amsn.org/certification/get-certified">Get Certified</a>
                      <ul class="submenu dropdown-menu">
                        <li><a
                            href="https://www.amsn.org/certification/get-certified/certification-comparison">Certification
                            Comparison</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.amsn.org/certification/cmsrn-resources-prep-materials">CMSRN
                        Resources & Prep Materials</a></li>
                    <li><a href="https://www.amsn.org/certification/renew-your-certification">Renew
                        Your Certification</a></li>
                    <li><a href="https://www.amsn.org/certification/contact-hours-tracker">Contact
                        Hours Tracker</a></li>
                    <li><a href="https://www.amsn.org/certification/digital-badges-certification-verification">Digital
                        Badges & Verification</a></li>
                    <li><a href="https://www.amsn.org/certification/statistics-policies">Statistics
                        & Policies</a></li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/career-development">Career Development</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/about-amsn/what-med-surg-nursing">What Is
                        Med-Surg Nursing?</a></li>
                    <li><a href="https://amsn.nationalhealthcarecareers.com/">Career Center</a></li>
                    <li><a href="https://www.amsn.org/career-development/professional-development">Professional
                        Development</a>
                      <ul class="submenu dropdown-menu">
                        <li><a href="https://library.amsn.org/amsn/">CNE Library</a></li>
                        <li><a
                            href="https://www.amsn.org/career-development/professional-development/med-surg-talks">Med-Surg
                            Talks</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.amsn.org/career-development/mentoring">Mentoring</a>
                    </li>
                    <li><a href="https://www.amsn.org/index.php/career-development/grants-scholarships">Grants
                        & Scholarships</a></li>
                    <li><a href="https://www.amsn.org/career-development/awards">Awards</a>
                      <ul class="submenu dropdown-menu">
                        <li><a href="https://www.amsn.org/career-development/awards/individual-awards">Individual
                            Awards</a></li>
                        <li><a href="https://www.amsn.org/career-development/awards/unit-award-amsn-prism-award">Unit
                            Award - AMSN PRISM Award</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/practice">Practice</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/practice/topics">Topics</a></li>
                    <li><a href="https://www.amsn.org/practice/covid-19-resources-nurses">COVID-19
                        Resources for Nurses</a></li>
                    <li><a href="https://www.amsn.org/publications/core-curriculum-medical-surgical-nursing">Core
                        Curriculum for Medical-Surgical Nursing</a></li>
                    <li><a href="https://www.amsn.org/publications/scope-and-standards">Scope and
                        Standards</a></li>
                    <li><a href="https://www.amsn.org/practice/healthcare-initiatives">Healthcare
                        Initiatives</a>
                      <ul class="submenu dropdown-menu">
                        <li><a
                            href="https://www.amsn.org/practice/healthcare-initiatives/abbott-nutrition-health-institute">Abbott
                            Nutrition Health Institute</a></li>
                        <li><a
                            href="https://www.amsn.org/practice/healthcare-initiatives/nightingale-challenge">Nightingale
                            Challenge</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.amsn.org/practice/med-surg-moments-amsn-podcast">Podcast:
                        MedSurg Moments</a></li>
                    <li><a href="https://www.amsn.org/practice/research-studies">Research
                        Studies</a>
                      <ul class="submenu dropdown-menu">
                        <li><a
                            href="https://www.amsn.org/index.php/practice/research-studies/participate-open-research-studies">Participate
                            in Open Research Studies</a></li>
                        <li><a href="https://www.amsn.org/practice/research-studies/recruit-your-research">Recruit
                            for Your Research</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/advocacy">Advocacy</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/index.php/advocacy/policy-agenda">Policy
                        Agenda</a>
                      <ul class="submenu dropdown-menu">
                        <li><a href="https://www.amsn.org/index.php/advocacy/policy-agenda/safe-staffing">Safe
                            Staffing</a></li>
                        <li><a
                            href="https://www.amsn.org/index.php/advocacy/policy-agenda/social-determinants-health">Social
                            Determinants of Health</a></li>
                        <li><a
                            href="https://www.amsn.org/index.php/advocacy/policy-agenda/nurse-workforce-development">Nurse
                            Workforce Development</a></li>
                        <li><a
                            href="https://www.amsn.org/advocacy/policy-agenda/medical-surgical-nurses-leadership">Medical-Surgical
                            Nurses In Leadership</a></li>
                        <li><a
                            href="https://www.amsn.org/advocacy/policy-agenda/safe-health-care-workplaces-and-mutual-professional-respect">Safe
                            Health Care Workplaces and Mutual Professional Respect</a></li>
                        <li><a href="https://www.amsn.org/advocacy/policy-agenda/focusing-opioid-crisis">Focusing
                            on the Opioid Crisis</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.amsn.org/index.php/about-amsn/amsn-positions-issues-nursing">AMSN
                        Positions on Issues In Nursing</a></li>
                    <li><a href="https://www.amsn.org/advocacy/take-action">Take Action</a></li>
                    <li><a href="https://www.amsn.org/advocacy/advocacy-training">Advocacy
                        Training</a></li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/publications">Publications</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/publications/core-curriculum-medical-surgical-nursing">Core
                        Curriculum for Medical-Surgical Nursing</a></li>
                    <li><a href="https://www.amsn.org/publications/scope-and-standards">Scope and
                        Standards</a></li>
                    <li><a href="https://www.amsn.org/publications/medsurg-nursing-journal">MEDSURG
                        Nursing Journal</a></li>
                    <li><a href="https://www.amsn.org/publications/med-surg-matters">Med-Surg
                        Matters</a></li>
                    <li><a href="https://www.amsn.org/index.php/publications/author-opportunities">Author
                        Opportunities</a></li>
                    <li><a href="https://www.amsn.org/practice/med-surg-moments-amsn-podcast">Podcast:
                        MedSurg Moments</a></li>
                    <li><a href="https://www.amsn.org/publications/amsn-bookstore">AMSN
                        Bookstore</a></li>
                    <li><a href="https://www.amsn.org/publications/amsn-blog">The AMSN Blog</a></li>
                  </ul>
                </li>
                <li><a href="https://www.amsn.org/events">Events</a>
                  <ul class="dropdown-menu">
                    <li><a href="https://www.amsn.org/events/calendar">Calendar</a></li>
                    <li><a href="https://convention.amsn.org/">AMSN Annual Convention</a></li>
                    <li><a href="https://www.amsn.org/events/medical-surgical-nurses-week">Medical-Surgical
                        Nurses Week</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</header>
