import { Component } from '@angular/core';
import { Globals } from './globals';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
declare var $, Swal: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public globals: Globals, private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) { }

  ngOnInit(): void {
    console.log(this.globals.institute_details);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.titleService.setTitle('SAT | ' + data.title)
      });
    });

    // Call on page load and window resize
    // this.adjustWrapContentHeight();
    // $(window).resize(this.adjustWrapContentHeight());

    // this.router.events
    //   .pipe(
    //     filter(event => event instanceof NavigationEnd)
    //   )
    //   .subscribe((event: NavigationEnd) => {
    //     this.adjustWrapContentHeight(event);
    //   });


  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }



  // adjustWrapContentHeight(event?: NavigationEnd): void {
  //   if ($(window).width() < 3000) {
  //     const headerHeight = $('header').outerHeight() || 0;
  //     const navHeight = $('.nagivation_block').outerHeight() || 0;
  //     const footerHeight = $('footer').outerHeight() || 0;
  //     const windowHeight = $(window).height() || 0;
  //     const wrapContentHeight = windowHeight - headerHeight - navHeight - footerHeight;

  //     $('.inner_content_block').css('height', `${wrapContentHeight}px`);

  //   } else {
  //     $('.inner_content_block').css('height', 'auto'); // Reset height for larger screens
  //   }
  // }



}
