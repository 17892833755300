<div class="nagivation_block my_products_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <nav class="navbar navbar-expand-lg navbar-light" *ngIf="globals.institute_details!=null">
                    <!-- <a href="https://www.amsn.org/" target="_blank"
                        class="backwebsite_btn d-lg-none d-xl-none p-0 float-start">Visit AMSN Website</a> -->
                    <button class="navbar-toggler float-end" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li *ngIf="globals.permissions['assessment-list']"
                                [ngClass]="{'active':((globals.currentLink.split('/')[1]=='assessment' && (globals.currentLink.split('/')[2]!='license-pack')))}">
                                <a routerLink="/assessment/my-assessments">My Subscriptions</a>
                            </li>
                        </ul>
                    </div>


                    <div *ngIf="administrator_present || connector_present"
                        class="products_menu align-items-center d-flex gap-3">
                        <div class="d-flex" kendoTooltip position="top">
                            <div class="dropdown">
                                <a class="product_text dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <span>My Products</span>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <div class="product_half">
                                        <ul>
                                            <li *ngIf="connector_present">
                                                <a (click)="goToDirectConnector()"><img
                                                        src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                        class="img-fluid" /><span>Connector</span>
                                                </a>
                                                <div class="clearfix"></div>
                                            </li>

                                            <li class="active">
                                                <img src="{{globals.cndURL}}assets/images/sat.png" alt=""
                                                    class="img-fluid" /><span>IFMA’s self-assessment</span>
                                                <div class="clearfix"></div>
                                            </li>

                                            <li *ngIf="administrator_present">
                                                <a (click)="viewAdmin()"><img
                                                        src="{{globals.cndURL}}assets/images/crm.png" alt=""
                                                        class="img-fluid" /><span>Administration</span>
                                                </a>
                                                <div class="clearfix"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>




                    <!-- <div *ngIf="administrator_present || connector_present" class="products_menu"><span
                            class="product_text">My Products <i class="fa fa-caret-down ms-1"></i></span>
                        <div class="dropdown-menu" kendoTooltip position="left">
                            <div class="product_half">
                                <ul>
                                    <li *ngIf="connector_present">
                                        <a (click)="goToDirectConnector()"><img
                                                src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                class="img-fluid" /><span>Connector</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>

                                    <li class="active">
                                        <img src="{{globals.cndURL}}assets/images/sat.png" alt=""
                                            class="img-fluid" /><span>Self-Assessment Tool</span>
                                        <div class="clearfix"></div>
                                    </li>

                                    <li *ngIf="administrator_present">
                                        <a (click)="viewAdmin()"><img src="{{globals.cndURL}}assets/images/crm.png"
                                                alt="" class="img-fluid" /><span>Administration Tool</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->


                </nav>
            </div>
        </div>
    </div>
</div>