import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $,Swal: any;

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.css']
})
export class LandingDashboardComponent implements OnInit {

  instituteList;
  individual=null;
  role = 0;
  individual_present = false;
  amsn_present = false;
  oess_present = false;
  institute_count = 0;
  selectForm: UntypedFormGroup;

  constructor(public globals: Globals,private AuthService: AuthService, private router: Router, 
    private fb: UntypedFormBuilder, private CookieService: CookieService) { }

  ngOnInit(): void {  
    
    this.globals.isLoading = true;
    this.selectForm = this.fb.group({
      role: ['', [Validators.required]]
    });
    $('input:radio').click(function () {
      $("label").removeClass('checked');
      $(this).parent().addClass('checked');
    });

    this.AuthService.getAuthUser()
    .then((data) => {
      if (this.globals.authData && this.globals.authData.list.length > 1) {
        let check = 0; let inactive_check = 0; let total_check = 0; let active_check = 0; let index = 0; 
        for(let i=0; i<this.globals.authData.list.length; i++){
          if(this.globals.authData.list[i].role_value==1 || this.globals.authData.list[i].role_value==2){
            if(this.globals.authData.list[i].institute!='' && this.globals.authData.list[i].institute.is_active==0){
             
            } else {
              check++;
              this.globals.authData.list[i].index_number = i;
              this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
              this.globals.institute_details = this.globals.authData.list[i];
              if(this.globals.institute_details && (this.globals.institute_details.role_value==4 || this.globals.institute_details.role_value==5)){
                window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
              } else {
                window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
              }                  
              break;
            }                    
          }
          if(this.globals.authData.list[i].role_value==5){
            this.globals.authData.list[i].index_number = i;
            this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
            this.globals.institute_details = this.globals.authData.list[i];
            if(this.globals.institute_details && (this.globals.institute_details.role_value==4 || this.globals.institute_details.role_value==5)){
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            } else {
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            }   
            break; 
          }
          if(this.globals.authData.list[i].institute!=''){
            if(this.globals.authData.list[i].institute.is_active==0){
              inactive_check++;
            } else {
              index = i;
              active_check++;
            }
            total_check++;
          }                  
        }  
        if(active_check == 1 && total_check==this.globals.authData.list.length){
            let i =index;
            this.globals.authData.list[i].index_number = i;
            this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
            this.globals.institute_details = this.globals.authData.list[i];
            if(this.globals.institute_details && (this.globals.institute_details.role_value==4 || this.globals.institute_details.role_value==5)){
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            } else {
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            }    
        } else{
          if(inactive_check==total_check){
            this.AuthService.logout()
            .then((data) => { 
              this.globals.isLoading = false;       
              window.location.href = this.globals.baseUrl+'/login';
            },
            (error) => {
              this.globals.isLoading = false;
              this.globals.errorSweetAlert();
            });
          }  else if(check==0){                
            let i =index;
            this.globals.authData.list[i].index_number = i;
            this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
            this.globals.institute_details = this.globals.authData.list[i];
            if(this.globals.institute_details && (this.globals.institute_details.role_value==4 || this.globals.institute_details.role_value==5)){
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            } else {
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            }    
           
          }
        }
        
      } else if (this.globals.authData.list.length == 1) {
        if(this.globals.authData.list[0].institute!='' && this.globals.authData.list[0].institute.is_active==0){
          this.AuthService.logout()
          .then((data) => { 
            this.globals.isLoading = false;       
            window.location.href = this.globals.amsnLoginUrl;
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
        } else {
          this.globals.authData.list[0].index_number = 0;
          this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[0])), 365, '/', this.globals.CookieDomainUrl);
          this.globals.institute_details = this.globals.authData.list[0];
          if(this.globals.institute_details && (this.globals.institute_details.role_value==1 || this.globals.institute_details.role_value==2 || this.globals.institute_details.role_value==3)){
            window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
          } else {
            window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
          }  
        }        
      } else if(this.globals.authData.list.length == 0) {
        this.AuthService.logout()
        .then((data) => { 
          this.globals.isLoading = false;       
          window.location.href = this.globals.amsnLoginUrl;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
      }

    }, (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });



  }

  ngAfterViewInit (){    
    let check1 = (this.globals.institute_details && this.globals.institute_details.institute=="") ? true : false;
    let check2 = (this.globals.institute_details && this.globals.institute_details.institute!="") ? true : false;
    let index = check2 ? this.globals.institute_details.index_number : 0;
    let role = (this.globals.institute_details && this.globals.institute_details.role_value) ? this.globals.institute_details.role_value : "null";
  
    setTimeout(function(){
      if(check1 && role==5){
        $('#Individual').prop("checked", true);
      } else if(check1 && role==1) {
        $('#oess').prop("checked", true);
      } else if(check1 && role==2) {
        $('#amsn').prop("checked", true);
      } else if(check2) {
        $('#Institute').prop("checked", true);
      }
      if(check2){
        $('#institute'+index).prop("checked", true);
      }
    }, 1000);
  }
  advanced_search_block(){
    $(".advanced_search_block").toggleClass("active");
    $(".search_main_block").toggleClass("right_slide");
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  changeRole(){ 
    if(this.role==0){
      this.globals.sweetAlert('warning','Select Role','Please select any role.');
    } else {
      localStorage.removeItem('getUserPermission');
      this.globals.permissions = {};
      if(this.role==5 || this.role==1 || this.role==2){
        let institute_details = null;
        if(this.role==5){          
          this.globals.authData.list.forEach(function (value) {
            if(value.institute=="" && value.role_value==5){
              institute_details = value;
            }
          });
        } else {        
          this.globals.authData.list.forEach(function (value) {
            if(value.role_value==1 || value.role_value==2){
              institute_details = value;
            }
          });
        }
        this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details)), 365, '/', this.globals.CookieDomainUrl);
        this.globals.institute_details = institute_details;
        if(this.globals.institute_details && (this.globals.institute_details.role_value==1 || this.globals.institute_details.role_value==2 || this.globals.institute_details.role_value==3)){
          window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
        } else {
          window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
        }        
      } else {
        if(this.institute_count==1){
          let institute_details = null;
          this.globals.authData.list.forEach(function (value) {
            if(value.institute!=""){
              institute_details = value;
            }
          });
          this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details)), 365, '/', this.globals.CookieDomainUrl);
          this.globals.institute_details = institute_details;
          if(this.globals.institute_details && (this.globals.institute_details.role_value==1 || this.globals.institute_details.role_value==2 || this.globals.institute_details.role_value==3)){
            window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
          } else {
            window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
          }  
        } else {
          let index = $('input[name="role_select"]:checked').val();
          if(index==undefined){
            this.globals.sweetAlert('warning','Select Organization','Please select any organization.');
          } else {
            this.globals.authData.list[index].index_number = index;
            this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[index])), 365, '/', this.globals.CookieDomainUrl);
            this.globals.institute_details = this.globals.authData.list[index];
            if(this.globals.institute_details && (this.globals.institute_details.role_value==1 || this.globals.institute_details.role_value==2 || this.globals.institute_details.role_value==3)){
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            } else {
              window.location.href = this.globals.baseUrl+'/assessment/my-assessments';
            }  
          }
        }
      }
    }
  }

  clearRole(){
    this.globals.institute_details = null;
    if(this.individual_present){
      this.role = 0;

     
    }
    $('input[name="role"]:checked').prop("checked", false);
    $('input[name="role_select"]:checked').prop("checked", false);
    this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
    localStorage.removeItem('getUserPermission');
    this.globals.permissions = {};
  }

}
