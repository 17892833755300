import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningPathService } from '../app-assessment_covid/services/learning-path.service';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent implements OnInit {
  permissions: any = [];
  accessConfigData: boolean = false;
  administrator_present: boolean = false;
  connector_present: boolean = false;
  covid_present: boolean = false;
  dcm_present: boolean = false;
  productList: any = [];
  is_individual = false;
  highest_role_value = 0;
  org_dcm_present: boolean = false;
  flag: boolean = false;
  completeAssessmentList:any
  selectedAssessment:any
  clickTabFlag:string
  public opened = false;
  
  constructor(
    public globals: Globals,
    private authService: AuthService,
    private CookieService: CookieService,
    private router: Router,
    private leanringPathService: LearningPathService,
    
  ) {}

  ngOnInit(): void {
    this.getPermission();
    if(this.globals.authData.id!=this.CookieService.get('user_token')){
      setTimeout(() => {
        this.authService.getAuthUser()
        .then((data) => { 
          this.getMyProductData();
        },
        (error) => {
          this.globals.errorSweetAlert();
        });
      }, 500);
    } else {
      this.getMyProductData();
    }  

    this.accessConfigData =
      $.inArray(
        this.globals.authData.id,
        this.globals.usersAccessConfigData
      ) !== -1
        ? true
        : false;

    if (
      this.globals.institute_details &&
      this.globals.institute_details.institute &&
      this.globals.institute_details.institute.id
    ) {
      this.is_individual = false;
    } else {
      this.is_individual = true;
    }
  }

ngAfterViewInit() {
  this.leanringPathService.currentFlag.subscribe(
    (flag) => (this.flag = flag)
  );
}

  getMyProductData() {
    if (this.globals.authData.id) {
      let postdata = { user_id: this.globals.authData.id };
      this.authService.getUserRoleList(postdata).then(
        (data) => {
          let productList = data['data'];

          let connector_present = false;
          let covid_present = false;
          let dcm_present = false;
          let org_dcm_present = false;
          let administrator_present = false;
          const current_role: any = this.globals.institute_details.role_value;

          this.productList = productList.filter((value) => {
            if (this.highest_role_value == 0) {
              this.highest_role_value = value.role_value;
            } else {
              if (value.role_value == 1) {
                this.highest_role_value = 1;
              } else if (
                value.role_value == 2 &&
                this.highest_role_value != 1
              ) {
                this.highest_role_value = 2;
              } else if (
                value.role_value == 6 &&
                this.highest_role_value != 1 &&
                this.highest_role_value != 2
              ) {
                this.highest_role_value = 6;
              } else if (
                value.role_value == 3 &&
                this.highest_role_value != 1 &&
                this.highest_role_value != 2 &&
                this.highest_role_value != 6
              ) {
                this.highest_role_value = 3;
              }
            }

            if (value.role_value == 1 || value.role_value == 2) {
              value.product_list.forEach(function (item) {
                if (item.product.value == 1) {
                  connector_present = true;
                } else if (item.product.value == 2) {
                  administrator_present = true;
                } else if (item.product.value == 3) {
                  administrator_present = true;
                }
              });
            } else if (value.role_value == 5) {
              value.product_list.forEach(function (item) {
                if (item.product.value == 2) {
                  covid_present = true;
                } else if (item.product.value == 3) {
                  dcm_present = true;
                }
              });
            } else if (value.role_value == 4) {
              value.product_list.forEach(function (item) {
                if (item.product.value == 3) {
                  org_dcm_present = true;
                }
              });
            } else if (value.role_value == 3 || value.role_value == 6) {
              value.product_list.forEach(function (item) {
                if (item.product.value == 1) {
                  connector_present = true;
                } else if (item.product.value == 2) {
                  administrator_present = true;
                } else if (item.product.value == 3) {
                  administrator_present = true;
                  org_dcm_present = true;
                }
              });
            }
            return value;
          });

          this.productList;
          this.connector_present = connector_present;
          this.covid_present = covid_present;
          this.dcm_present = dcm_present;
          this.org_dcm_present = org_dcm_present;
          this.administrator_present = administrator_present;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    }
  }

  getPermission() {
    if (
      localStorage.getItem('last_updated_date_role_permission') &&
      localStorage.getItem('user_role_permission')
    ) {
      this.authService.getLastUpdatedDateOfRolePermission().then(
        (res: any) => {
          let lastUpdatedDateRolePermission = new Date(res['data'].updated_at);
          let cookie_date = new Date(
            window.atob(
              localStorage.getItem('last_updated_date_role_permission')
            )
          );
          if (lastUpdatedDateRolePermission <= cookie_date) {
            if (
              window.atob(this.CookieService.get('current_role_value')) ==
              this.globals.institute_details?.role_value
            ) {
              let data = JSON.parse(
                window.atob(localStorage.getItem('user_role_permission'))
              );
              this.globals.permissions = data;
            } else {
              this.CookieService.set(
                'current_role_value',
                window.btoa(this.globals.institute_details.role_value),
                365,
                '/',
                this.globals.CookieDomainUrl
              );
              let permission_data = JSON.parse(
                window.atob(localStorage.getItem('all_role_permission'))
              );
              let active_license =
                this.globals.institute_details.active_license > 0 ? 0 : 0;
              let retrievedObject1 = permission_data.filter((item) => {
                if (
                  item.role_value ==
                    this.globals.institute_details.role_value &&
                  item.is_accessible_after_expire == active_license
                ) {
                  return item;
                }
              })[0].permission_list;

              let user_role_permission = {};
              let index;
              this.globals.permissionText.forEach(function (menu, key) {
                index = retrievedObject1.findIndex(
                  (retrievedObject1) =>
                    retrievedObject1.slug === menu.key &&
                    retrievedObject1.has_access == 1
                );
                if (index != -1) {
                  user_role_permission[menu.key] = true;
                } else {
                  user_role_permission[menu.key] = false;
                }
              });
              localStorage.setItem(
                'user_role_permission',
                window.btoa(JSON.stringify(user_role_permission))
              );
              this.globals.permissions = user_role_permission;
            }
          } else {
            this.authService.getAllRolePermissionData1();
          }
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.authService.getAllRolePermissionData1();
    }
  }

  goToDirectConnector() {
    let institute_details = null;

    if (this.highest_role_value == 1 || this.highest_role_value == 2) {
      let role = this.highest_role_value;
      this.globals.authData.list.forEach(function (value) {
        if (value.role_value == role) {
          institute_details = value;
        }
      });
    } else {
      institute_details = this.productList.filter(
        (s) => s.role_value == this.highest_role_value
      )[0];
    }

    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );

    window.location.href = this.globals.connectorUrl + '/overview-dashboard';

  }

  registerOrg() {
    this.CookieService.delete(
      'institute_details',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product_id',
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href =
      this.globals.connectorUrl + '/organization-registration';
  }

  viewIndividualDCM() {
    let institute_details = this.globals.authData.list.filter(
      (s) => s.role_value == 5
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = this.globals.baseUrl;
  }

  viewOrgDCM() {
    let institute_details = this.productList.filter(
      (s) => s.role_value == 3 || s.role_value == 4 || s.role_value == 6
    )[0];

    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );

    window.location.href = this.globals.baseUrl;
  }

  viewAdmin() {
   
    this.CookieService.delete(
      'current_product',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product_id',
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = this.globals.adminUrl;
    
  }

 

  viewSAT() {
 
    this.CookieService.delete(
      'current_product',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product_id',
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = this.globals.dcmUrl;
  }

  public close(): void {
    this.opened = false;
  }

  goToLearningPath(flag: any) {
    this.clickTabFlag = flag;
    const userClickData = localStorage.getItem('userClickLp');
    var userClickEvent = userClickData ? JSON.parse(userClickData) : null;
    if(this.globals.currentLink.split('/')[2]=='learning-path'){
      this.opened = false
      this.router.navigate(['/assessment/learning-path']);
    }
    else{
      if(userClickEvent?.assementId){
        this.router.navigate(['/assessment/learning-path']);
      }
      else{
        this.getDialougeData();
      }
    }
    
  
  }

  gotoFavourite(flag: any) {
   
     if(flag == 'direct'){
       var directData ={
          direct:true,
          overall_rating: '1'
        }
        localStorage.setItem('userClickFv', JSON.stringify(directData));
        this.router.navigate(['/assessment/favorite']);
      }
  }
  async getDialougeData(): Promise<void> {
    this.globals.isLoading = true;
    var entity = {
      user_id: this.globals.authData.id,
    };
    try {
      const response: any = await this.leanringPathService.getCompletedRes(
        entity
      );
      this.completeAssessmentList = response.data;
      this.opened = true;

    } catch (error) {
    } finally {
      this.globals.isLoading = false;
    }
  }

  assessmentChange(assessment: any) {
    this.selectedAssessment = assessment;
  }

  onSubmit(form: any) {
    if (form.valid) {
      if (this.clickTabFlag == 'learning') {
        var overAllData = {
          overAll: true,
          overall_rating: this.selectedAssessment.overall_rating,
          assementId: this.selectedAssessment.id,
          institute_name: this.selectedAssessment.institute_name,
        };
        localStorage.setItem('userClickLp', JSON.stringify(overAllData));
        this.opened = false;
        this.globals.isLoading = false;
        form.reset();
        this.router.navigate(['/assessment/learning-path']);
      } 
      
    } else {
    }
  }
}
