<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7">
                <div class="copyright_terms_block">
                    Copyright &copy; <span class="footer_year"></span>, <a href="https://www.ifma.org/" target="_blank">International Facility Management Association (IFMA)</a>. |
                    Powered by <a href="https://www.theopeneyes.com/" target="_blank">OpenEyes Technologies Inc.</a>
                </div>
                <div class="copyright_terms_block mt-2">No materials, including graphics, may be reused, modified, or
                    reproduced without written permission. | Version: {{CurrentVersion}}</div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                <div class="social_links_block">
					<!-- <a href="#" class="feedback_a" target="_blank">User Manual</a> -->
                    <a href="https://faqs.ifma.uatbyopeneyes.com/home" class="feedback_a" target="_blank">FAQs</a>
                    <!-- <a href="#" class="feedback_a" target="_blank">Share a Feedback</a> -->
                    <a href="https://www.facebook.com/InternationalFacilityManagementAssociation" target="_blank"><i class="fa fa-facebook"></i></a>
                    <a href="https://twitter.com/IFMA" target="_blank"><i class="fa fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/ifma" target="_blank"><i
                            class="fa fa-linkedin-square"></i></a>
                    <a href="https://www.instagram.com/ifma_hq/" target="_blank"><i class="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/ifmaglobal" target="_blank"><i
                            class="fa fa-youtube-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="clearfix"></div>