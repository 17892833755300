import { Injectable } from '@angular/core';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;


import permissionText from "../assets/data/permissionTranslationText.json";

@Injectable()
export class Globals { 

  constructor(private http: HttpClient, private CookieService: CookieService) { }
  breadcrumbs = ['', '', ''];
  baseAPIUrl: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  authUrl: string = environment.authUrl;
  TestMode: boolean = environment.TestMode;
  cndURL: string = environment.cndURL;
  covidUrl: string = environment.covidUrl;
  connectorUrl: string = environment.connectorUrl;
  adminUrl: string = environment.adminUrl;
  dcmUrl: string = environment.baseUrl;
  amsnLoginUrl: string = environment.ssoLoginUrl+environment.authUrl+'/authentication';
  amsnLogoutUrl: string = environment.ssoLogoutUrl+environment.authUrl;
  headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
  IsLoggedIn: boolean = false;
  isLoading: boolean = false;
  currentLink: string = '';
  currentModule: string = '';
  authData = localStorage.getItem('auth_user')
  ? JSON.parse(window.atob(localStorage.getItem('auth_user')))
  : this.CookieService.get('auth_user')
    ? JSON.parse(window.atob(this.CookieService.get('auth_user')))
    : null;
  todaysdate: string = '';
  institute_details = this.CookieService.get('institute_details') ? JSON.parse(window.atob(this.CookieService.get('institute_details'))) : null;
  institute_id = this.CookieService.get('institute_details') && JSON.parse(window.atob(this.CookieService.get('institute_details'))).institute ? JSON.parse(window.atob(this.CookieService.get('institute_details'))).institute.id : null;
  sweetalertmessage: string = "<span class='close_msg'>This message box will auto close in <b></b>seconds!</span>";
  sweetalertmessagePayment: string = "<span class='close_msg'>Redirecting to payment form in <b></b>seconds!</span>";

  sweetalerttimer: number = 5000;
  permissionText = permissionText;
  permissions = {};
  usersAccessConfigData = ['80f93f87-5f6e-4047-a84d-269ab39fac0c'];
  maximumSearchCriteriaDisplay = 4;
  CookieDomainUrl: string = environment.CookieDomainUrl;
  selected_institute = this.CookieService.get('selected_institute') ? JSON.parse(window.atob(this.CookieService.get('selected_institute'))) : null;
  Recently_institutes = this.CookieService.get('Recently_institutes') ? JSON.parse(window.atob(this.CookieService.get('Recently_institutes'))) : [];
  dialogOpened = false;
  public full_month_name : any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public short_month_name : any =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // grid configuration
  pagesizes = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 }
  ];
  pageIndex = 6;

  virtual = {
    itemHeight: 30,
    pageSize: 15
  };

  sweetAlert(icon,title,html){
    let timerInterval;
    Swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: true,
      timer: this.sweetalerttimer,
      html: html + this.sweetalertmessage,
      timerProgressBar: true,
    
      didOpen: () => {
       
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
        }, 100)

      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    });
  }

  errorSweetAlert(msg:any = ""){
    
  }

  saveRecentlyInstitutes(institute_id){
    /// start save recently organization ///
    let Recently_institutes = this.Recently_institutes;
    let index = $.inArray(institute_id, Recently_institutes);
    if(index !== -1){
      Recently_institutes.splice(index,1);
    }      
    Recently_institutes.unshift(institute_id);
    Recently_institutes.splice(3, 1);
    this.CookieService.set('Recently_institutes', window.btoa(JSON.stringify(Recently_institutes)), 365, '/', this.CookieDomainUrl);
    /// end save recently organization ///
 }

 adjustWrapContentHeight(): void {
  if ($(window).width() < 3000) {
    const headerHeight = $('header').outerHeight() || 0;
    const navHeight = $('.nagivation_block').outerHeight() || 0;
    const footerHeight = $('footer').outerHeight() || 0;
    const windowHeight = $(window).height() || 0;
    const wrapContentHeight = windowHeight - headerHeight - navHeight - footerHeight;
    $('.inner_content_block').css('min-height', `${wrapContentHeight}px`);
  } else {
    $('.inner_content_block').css('min-height', 'auto');
  }
}

debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: any;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

scrollToTop(){
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}

}
