<div class="inner_content_block landing_dashboard" [ngClass]="{'hasbreadcrumb_block': globals.institute_details && globals.authData.list.length>1}">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start" *ngIf="!individual_present">Your Dashboards</h1>
                    <h1 class="float-start" *ngIf="individual_present">Your Dashboards</h1>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                
                <div class="clearfix"></div> 
                <div class="institute_dashbaord" *ngIf="role==3 && institute_count>1">
                    <div class="row">
                        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="box_search_block">
                                <p class="padding_15">You are either administrating or associated with more than one organization. To administer or view the dashboard, please select an organization.</p>
                                <div class="clearfix"></div>
                                <div class="filter_block">
                                    <ul>
                                        <li><span class="id_box mb-0"></span>ID</li>
                                        <li><i class="fa fa-map-marker"></i>Address</li>
                                        <li><i class="fa fa-phone"></i>Phone Number</li>
                                        <li><i class="fa fa-list-ul"></i>Type</li>
                                        <li><i class="fa fa-user"></i>Ownership</li>
                                    </ul>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>

                        <ng-container *ngFor="let insti of instituteList; let i=index;">
                           
                            <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex border_line"
                                *ngIf="insti.institute!='' && insti.institute.is_active==1">
                                <label class="institute_block" for="institute{{i}}">
                                    <input type="radio" id="institute{{i}}" name="role_select" value="{{i}}"
                                        kendoRadioButton />
                                    <span class="check_block"></span>
                                    <div class="content_block">
                                        <h5>{{insti.institute.institute_name}}</h5>
                                        <div *ngIf="insti.institute.provider_id" class="id_box">{{insti.institute.provider_id}}</div>
                                        <p><i class="fa fa-map-marker"></i>
                                            <span>{{insti.institute.address1}}<br>{{insti.institute.city}},
                                              
                                                {{insti.institute.states.state_abbreviation}} {{insti.institute.zipcode}}</span></p>
                                        <p><i class="fa fa-phone"></i>
                                            <span>{{insti.institute.phone_number | phone }}</span></p>
                                        <p *ngIf="insti.institute.institute_types && insti.institute.institute_types.display_text"><i class="fa fa-list-ul"></i>
                                            <span>{{insti.institute.institute_types.display_text}}</span></p>
                                        <p *ngIf="insti.institute.institute_owners && insti.institute.institute_owners.display_text"><i class="fa fa-user"></i>
                                            <span>{{insti.institute.institute_owners.display_text}}
                                            </span></p>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="button_block text-center">
                                        Your Role: <span>{{insti.role_name}}
                                        </span></div>
                                </label>
                            </div>
                        </ng-container>

                       

                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</div>