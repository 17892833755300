import { Injectable } from '@angular/core';
import { Globals } from '../../globals';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LearningPathService {

  constructor(private http: HttpClient, private globals: Globals) { }

  private flagSource = new Subject<boolean>;
  currentFlag = this.flagSource.asObservable();

  updateFlag(flag: boolean) {
    this.flagSource.next(flag);
  }

  getResourcesListSat(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/get-resources-list-sat',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  getdomainList() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/domain/get-domain/2')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getSubdomainList(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/subdomain/get-domain',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  setFavourite(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/add-favourite-resource',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getFavoriteRes(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/get-favourite-resources-list-sat',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  revomeFavourite(itemId:any) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/remove-favourite-resource/'+itemId)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  getCompletedRes(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/get-completed-results-by-user-id',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  exportToExcel(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/export-resources-learning-path',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  favexportToExcel(entity){
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/export-resources-favourite',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


}
