<div class="nagivation_block my_products_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <nav class="navbar navbar-expand-lg navbar-light" *ngIf="globals.institute_details!=null">
                    <button class="navbar-toggler float-end" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li
                                [ngClass]="{'active':(((globals.currentLink.split('/')[2]=='my-assessments' && (globals.currentLink.split('/')[2]!='license-pack') || globals.currentLink.split('/')[2]=='result' ||
                            globals.currentLink.split('/')[2]=='give-assessment' || globals.currentLink.split('/')[2]=='edit-assessment' || globals.currentLink.split('/')[2]=='purchase-subscription' ||
                             globals.currentLink.split('/')[2]=='purchase-payment-history')))}">
                                <a  routerLink="/assessment/my-assessments">My Subscriptions</a>
                            </li>
                            
                          
    
                            <li *ngIf="(globals.institute_details ) && flag"
                                [ngClass]="{'lp-step-1 active':((globals.currentLink.split('/')[2]=='learning-path'))}"><a
                                (click)="goToLearningPath('learning')">learning path</a></li>

                                <li *ngIf="(globals.institute_details ) && flag"
                                [ngClass]="{'active':((globals.currentLink.split('/')[2]=='favorite'))}"><a
                                (click)="gotoFavourite('direct')">Favorite</a></li>
                                    
                        </ul>
                    </div>


                    <div *ngIf="administrator_present || connector_present"
                        class="products_menu align-items-center d-flex gap-3">
                        <div class="d-flex" kendoTooltip position="top">
                            <div class="dropdown">
                                <a class="product_text dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <span>My Products</span>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <div class="product_half">
                                        <ul>
                                            <li *ngIf="connector_present">
                                                <a (click)="goToDirectConnector()"><img
                                                        src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                        class="img-fluid" /><span>Connector</span>
                                                </a>
                                                <div class="clearfix"></div>
                                            </li>

                                            <li class="active">
                                                <img src="{{globals.cndURL}}assets/images/sat.png" alt=""
                                                    class="img-fluid" /><span>IFMA’s self-assessment</span>
                                                <div class="clearfix"></div>
                                            </li>

                                            <li *ngIf="administrator_present && (globals.institute_details.role_value == 1 || globals.institute_details.role_value == 2)">
                                                <a (click)="viewAdmin()"><img
                                                        src="{{globals.cndURL}}assets/images/crm.png" alt=""
                                                        class="img-fluid" /><span>Administration</span>
                                                </a>
                                                <div class="clearfix"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>




                   


                </nav>
            </div>
        </div>
    </div>
</div>


<kendo-dialog *ngIf="opened" [minWidth]="550" [width]="550" (close)="close()">
    <kendo-dialog-titlebar>
      <h5 class="modal-title" id="switch_org_modalLabel">Select an Assessment</h5>
     
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>
      <p class="padding_15">
        Your completed assessments are listed below. Please select one to view its related learning resources.
      </p>
  
      <form #assessmentForm="ngForm" (ngSubmit)="onSubmit(assessmentForm)">
        <kendo-formfield>

            <label><span>*</span>Completed Assessments</label>
            <kendo-dropdownlist
              id="id"
              name="assessment"
              #assessmentDropdown="ngModel"
              [(ngModel)]="selectedAssessment"
              [textField]="'institute_name'"
              [valueField]="'id'"
              [virtual]="completeAssessmentList.length > 100"
              [data]="completeAssessmentList"
              (valueChange)="assessmentChange($event)"
              [defaultItem]="{ institute_name: 'Select an Assessment', id: null }"
              required
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span>
                  {{
                    dataItem?.is_purchased == 1
                      ? "Self-Purchased"
                      : dataItem.institute_name
                  }}
                </span>
              </ng-template>
      
              <ng-template kendoMultiSelectNoDataTemplate>
                <h6>NO ASSESSMENT FOUND</h6>
              </ng-template>
            </kendo-dropdownlist>
      
        </kendo-formfield>
        <div *ngIf="selectedAssessment?.id != null" class="mb-4">
          <p class="mb-2"><b>Selected Assessment</b></p>
          <div class="border_line assessment_width">
            <div class="institute_block pb-0">
              <div class="content_block p-3">
                <div class="d-flex flex-column">
                  <div
                    class="align-items-center d-flex justify-content-between mb-3"
                  >
                    <h5 class="float-start mb-0">
                      {{
                        selectedAssessment.is_purchased == 1
                          ? "Self-Purchased"
                          : selectedAssessment.institute_name
                      }}
                    </h5>
                    <div class="ms-3 status_block green">Completed</div>
                  </div>
                  <div class="clearfix"></div>
                  <p>
                    <i class="fa fa-calendar"></i
                    ><span
                      ><b>Purchased On:</b
                      >{{
                        selectedAssessment.assign_date
                          | date : "MMMM d, yyyy hh:mm a"
                      }}</span
                    >
                  </p>
                  <p>
                    <i class="fa fa-calendar"></i
                    ><span
                      ><b>Started On:</b
                      >{{
                        selectedAssessment.start_date
                          | date : "MMMM d, yyyy hh:mma"
                      }}</span
                    >
                  </p>
                  <p>
                    <i class="fa fa-calendar"></i
                    ><span
                      ><b>Expiration:</b>
                      {{
                        selectedAssessment.number_of_extension > 0
                          ? (selectedAssessment.valid_till_subs_pack_expiry
                            | date
                              : "MMMM d, yyyy hh:mm
                      a")
                          : (selectedAssessment.valid_till_assessment_expiry
                            | date
                              : "MMMM d, yyyy
                      hh:mm a")
                      }}</span
                    >
                  </p>
                  <p class="pb-0">
                    <i class="fa fa-calendar"></i
                    ><span
                      ><b>Completed On:</b
                      >{{
                        selectedAssessment.complete_date
                          | date
                            : "MMMM d, yyyy
                                                      hh:mm a"
                      }}</span
                    >
                  </p>
  
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="footer_button text-end">
          <button
            type="submit"
            [disabled]="assessmentForm.invalid || selectedAssessment?.id == null"
            class="all_btn theme_btn"
          >
            Select
          </button>
        </div>
      </form>
    </div>
  </kendo-dialog>
  <div class="clearfix"></div>