<div class="inner_content_block" [ngClass]="{'hasbreadcrumb_block': globals.institute_details && globals.authData.list.length>1}">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="clearfix"></div>
                <div class="title_block">
                    <h1 class="float-start">
                        Subdomain Preferences
                    </h1>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div class="grey_form_block no_after pb-2" *ngFor="let domain of this.domainWiseSubdomains | keyvalue">
                    <h5><span>Domain:</span> {{domain.key}}</h5>
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let subdomain_group of domain.value; let dg_index=index;">
                                <td class="width_25" *ngFor="let subdomain of subdomain_group; let d_index=index;">
                                    <div class="check_box">
                                        <input type="checkbox" id="{{domain.key}}{{dg_index}}{{d_index}}"
                                            (click)="toggle(subdomain, 'preferred_subdomain')"
                                            [checked]="subdomain.preferred_subdomain" kendoCheckBox />
                                        <label
                                            for="{{domain.key}}{{dg_index}}{{d_index}}">{{subdomain.subdomain_name}}</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="clearfix"></div>
                <div class="text-center">
                        <button kendoButton (click)="update()" primary="true" class="all_btn theme_btn">

                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>