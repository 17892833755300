import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $, Swal: any;
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.css'],
})
export class IntermediatePageComponent implements OnInit {
  constructor(
    public globals: Globals,
    private router: Router,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private CookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.globals.isLoading = true;

    // let token = this.route.snapshot.paramMap.get('token')
    //   ? this.route.snapshot.paramMap.get('token')
    //   : null;
    let token = this.route.snapshot.queryParams.IfmaToken
    ?this.route.snapshot.queryParams.IfmaToken
    :null;
    this.CookieService.set(
      'amsn_token',
      token,
      365,
      '/',
      this.globals.CookieDomainUrl
    );

    this.AuthService.checkAMSNLogin({
      usertoken: token,
      ip_address: null,
      user_agent: null,
    }).then(
      (data) => {
        this.AuthService.getAuthUser().then(
          (data) => {
            //this.globals.isLoading = false;
            if (
              this.globals.authData &&
              this.globals.authData.list.length > 1
            ) {
              
              let check = 0;
              let inactive_check = 0;
              let total_check = 0;
              let active_check = 0;
              let index = 0;
              for (let i = 0; i < this.globals.authData.list.length; i++) {

                if (
                  this.globals.authData.list[i].role_value == 1 ||
                  this.globals.authData.list[i].role_value == 2
                ) {

                  if (
                    this.globals.authData.list[i].institute != '' &&
                    this.globals.authData.list[i].institute.is_active == 0
                  ) {
                  } else {
                    check++;
                    this.globals.authData.list[i].index_number = i;
                    this.CookieService.set(
                      'institute_details',
                      window.btoa(
                        JSON.stringify(this.globals.authData.list[i])
                      ),
                      365,
                      '/',
                      this.globals.CookieDomainUrl
                    );
                    this.globals.institute_details =
                      this.globals.authData.list[i];
                    if (
                      this.globals.institute_details &&
                      (this.globals.institute_details.role_value == 4 ||
                        this.globals.institute_details.role_value == 5)
                    ) {
                      window.location.href =
                        this.globals.baseUrl + '/assessment/my-assessments';
                    } else {
                      window.location.href =
                        this.globals.baseUrl + '/assessment/my-assessments';
                    }
                    break;
                  }
                }
                if (this.globals.authData.list[i].role_value == 5) {
                  this.globals.authData.list[i].index_number = i;
                  this.CookieService.set(
                    'institute_details',
                    window.btoa(JSON.stringify(this.globals.authData.list[i])),
                    365,
                    '/',
                    this.globals.CookieDomainUrl
                  );
                  this.globals.institute_details =
                    this.globals.authData.list[i];
                  if (
                    this.globals.institute_details &&
                    (this.globals.institute_details.role_value == 4 ||
                      this.globals.institute_details.role_value == 5)
                  ) {
                    window.location.href =
                      this.globals.baseUrl + '/assessment/my-assessments';
                  } else {
                    window.location.href =
                      this.globals.baseUrl + '/assessment/my-assessments';
                  }
                  // break;
                }
                if (this.globals.authData.list[i].institute != '') {
                  if (this.globals.authData.list[i].institute.is_active == 0) {
                    inactive_check++;
                  } else {
                    index = i;
                    active_check++;
                  }
                  total_check++;
                }
              }
              if (
                active_check == 1 &&
                total_check == this.globals.authData.list.length
              ) {
                let i = index;
                this.globals.authData.list[i].index_number = i;
                this.CookieService.set(
                  'institute_details',
                  window.btoa(JSON.stringify(this.globals.authData.list[i])),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
                this.globals.institute_details = this.globals.authData.list[i];
                if (
                  this.globals.institute_details &&
                  (this.globals.institute_details.role_value == 4 ||
                    this.globals.institute_details.role_value == 5)
                ) {
                  window.location.href =
                    this.globals.baseUrl + '/assessment/my-assessments';
                } else {
                  window.location.href =
                    this.globals.baseUrl + '/assessment/my-assessments';
                }
              } else {
                if (inactive_check == total_check) {
                  // this.AuthService.logout().then(
                  //   (data) => {
                  //     this.globals.isLoading = false;
                  //     window.location.href = this.globals.baseUrl + '/login';
                  //   },
                  //   (error) => {
                  //     this.globals.isLoading = false;
                  //     this.globals.errorSweetAlert();
                  //   }
                  // );
                } else if (check == 0) {
                  let i = index;
                  this.globals.authData.list[i].index_number = i;
                  this.CookieService.set(
                    'institute_details',
                    window.btoa(JSON.stringify(this.globals.authData.list[i])),
                    365,
                    '/',
                    this.globals.CookieDomainUrl
                  );
                  this.globals.institute_details =
                    this.globals.authData.list[i];
                  if (
                    this.globals.institute_details &&
                    (this.globals.institute_details.role_value == 4 ||
                      this.globals.institute_details.role_value == 5)
                  ) {
                    window.location.href =
                      this.globals.baseUrl + '/assessment/my-assessments';
                  } else {
                    window.location.href =
                      this.globals.baseUrl + '/assessment/my-assessments';
                  }
                  // this.globals.institute_details = null;
                  // this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
                  // this.router.navigate(['/landing-dashboard']);
                }
              }
            } else if (this.globals.authData.list.length == 1) {
              if (
                this.globals.authData.list[0].institute != '' &&
                this.globals.authData.list[0].institute.is_active == 0
              ) {
                this.AuthService.logout().then(
                  (data) => {
                    this.globals.isLoading = false;
                    window.location.href = this.globals.amsnLoginUrl;
                  },
                  (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                  }
                );
              } else {
                this.globals.authData.list[0].index_number = 0;
                this.CookieService.set(
                  'institute_details',
                  window.btoa(JSON.stringify(this.globals.authData.list[0])),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
                this.globals.institute_details = this.globals.authData.list[0];
                if (
                  this.globals.institute_details &&
                  (this.globals.institute_details.role_value == 4 ||
                    this.globals.institute_details.role_value == 5)
                ) {
                  window.location.href =
                    this.globals.baseUrl + '/assessment/my-assessments';
                } else {
                  window.location.href =
                    this.globals.baseUrl + '/assessment/my-assessments';
                }
              }
            } else if (this.globals.authData.list.length == 0) {
              this.AuthService.logout().then(
                (data) => {
                  this.globals.isLoading = false;
                  window.location.href = this.globals.amsnLoginUrl;
                },
                (error) => {
                  this.globals.isLoading = false;
                  this.globals.errorSweetAlert();
                }
              );
            }
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          }
        );
      },
      (error) => {
        this.globals.isLoading = false;
        if (error.status == 400) {
          this.globals.sweetAlert(
            'warning',
            'Deactive',
            'Your account is deactive. so you cannot login.'
          );
        } else if (error.status == 401) {
          this.globals.sweetAlert(
            'warning',
            'Invalid Login Credentials',
            'Either email or password is incorrect.'
          );
        } else {
          this.globals.errorSweetAlert();
        }
      }
    );
  }
}
